import React, { ReactElement } from "react";

import styles from "./style.module.scss";

export default function GetMyFreeCase(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className={`${styles["mainSection"]} px-5 lg:px-0`}>
            <div className="py-[71px]">
                <p>
                    If You or Someone You Love Has Been Affected, Our Caring
                    Team Is Here to Help. Contact Us to Learn More About Your
                    Legal Options.
                </p>
                <div className="mt-9">
                    <button
                        onClick={() => scrollToTop()}
                        className={` sm:py-[18px] py-[11px] sm:px-16 px-4 sm:text-lg font-semibold rounded-lg ${styles["animation"]}`}
                    >
                        Get My Free Case Review
                    </button>
                </div>
            </div>
        </section>
    );
}
