import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";
export default function MainBanner(): ReactElement {
    const { domain } = useDomainContext();
    const router = useRouter();

    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={`mx-auto px-5 max-w-7xl ${styles["wrapper"]}`}>
                    <div className={`${styles["main-banner"]}`}>
                        <div className="flex flex-col md:flex-row items-center justify-center md:gap-16 gap-8 pb-10 pt-10 md:pt-20">
                            {domain.config?.formSideContent && (
                                <h2 className=" md:text-4xl text-2xl text-[#fff] md:leading-[50px] leading-9 font-bold max-w-[407px] z-50 flex justify-center items-center text-center md:text-left">
                                    {domain.config?.formSideContent ?? ""}
                                </h2>
                            )}

                            <Form
                                classes={{
                                    formClassName: styles["form"],
                                    stepTitleClassName: styles["step-title"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                    formProgressbarClassName:
                                        styles["progress-background"],
                                }}
                                colors={{
                                    progressBar: "#b0b0b0",
                                }}
                                showProgress={true}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
