import React, { ReactElement } from "react";
import SharedComponent from "./sharedComponent";

export default function MiddleSection(): ReactElement {
    const middleSectionComponents = [
        {
            title: "Does Talcum Powder Cause Cancer?",
            content: [
                "Using talcum powder from Johnson & Johnson has been linked to ovarian cancer, particularly for women who use it regularly over a long period of time. Recent studies have shown a 30% increase in ovarian cancer risk for those who apply talc-based baby powder to their genital area. As a result, Johnson & Johnson is facing numerous lawsuits for not disclosing the dangers of talcum powder and its link to ovarian cancer. If you have been affected, our team is here to help you seek justice and compensation. Contact us to learn more about your legal options.",
            ],
            image: "/talcum.claims/assets/images/talcumMiddleSectionImg1.png",
            differentStyle: false,
        },
        {
            title: "Who Is Eligible to File a Talcum Powder Lawsuit?",
            content: [
                "Women who have used talcum powder as part of their personal hygiene routine regularly and developed ovarian or fallopian tube cancer may be able to file a lawsuit.",
                "In addition, if the woman is deceased, her surviving family members may be able to file a lawsuit on her behalf.",
            ],
            image: "/talcum.claims/assets/images/talcumMiddleSectionImg2.png",
            differentStyle: true,
        },
    ];

    return (
        <section className="max-w-7xl mx-auto">
            {middleSectionComponents.map((component, index) => (
                <SharedComponent
                    key={index}
                    title={component.title}
                    content={component.content}
                    image={component.image}
                    differentStyle={component.differentStyle}
                />
            ))}
        </section>
    );
}
