import React, { ReactElement } from "react";

import TicIcon from "./TicIcon";
const list = [
    { icon: <TicIcon />, title: "Mesothelioma" },
    { icon: <TicIcon />, title: "Fallopian tube cancer" },
    { icon: <TicIcon />, title: "Epithelial Ovarian Cancer" },
    { icon: <TicIcon />, title: "Primary peritoneal cancer" },
];
export default function QualifyLawsuit(): ReactElement {
    return (
        <div className=" pl-5 flex flex-col md:flex-row mt-7 mb-7 md:mb-0 max-w-7xl mx-auto  ">
            <h3 className="max-w-[587px] mt-3 font-bold md:text-2xl text-lg md:leading-[42px] ">
                You May Qualify for a Lawsuit Against Johnson & Johnson, if You
                Were Diagnosed With:
            </h3>

            <div className="flex  flex-wrap  ">
                {list.map((point, index) => (
                    <div
                        key={index}
                        className="flex items-center gap-3  sm:ml-10 mt-5 w-[250px]"
                    >
                        <span>{point.icon}</span>
                        <p> {point.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
