import React from "react";

function TicIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            fill="none"
            viewBox="0 0 21 21"
        >
            <circle cx="10.261" cy="10.261" r="10.261" fill="#304598"></circle>
            <path
                fill="#fff"
                d="M8.283 15.391L4.605 11.77a1.095 1.095 0 010-1.565 1.136 1.136 0 011.59 0l2.088 2.056 6.044-5.951a1.137 1.137 0 011.59 0c.44.432.44 1.133 0 1.565L8.283 15.39z"
            ></path>
        </svg>
    );
}

export default TicIcon;
