import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import Image from "next/image";

export default function WhatTalcumPowder(): ReactElement {
    return (
        <section
            className={`${styles["wrapper"]} lg:py-14 py-10
                  flex flex-col lg:flex-row px-5 lg:gap-[110px] gap-[50px]  max-w-7xl mx-auto  mt-10`}
        >
            <div className={`max-w-[624px]  mx-auto lg:mx-0 md:pl-4 `}>
                <h2>What Is Talcum Powder?</h2>

                <p>
                    Talcum powder is made from talc, a mineral made of
                    magnesium, silicon, and oxygen. It is found in many
                    cosmetics and personal care products, like baby powder. Its
                    silky texture helps reduce friction and moisture on the
                    skin.
                </p>
            </div>
            <div
                className={`sm:w-[420px]  sm:min-w-[420px] sm:h-[255px] w-[300px] h-[200px] relative  mx-auto lg:mx-0 
                      
                    `}
            >
                <Image
                    src="/talcum.claims/assets/images/whatTalcumPowder.png"
                    layout="fill"
                    alt="Image"
                />
            </div>
        </section>
    );
}
