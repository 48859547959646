import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import Image from "next/image";

export default function SharedComponent({
    title,
    content,
    image,
    differentStyle = false,
}: {
    title: string;
    content: string[];
    image: string;
    differentStyle: boolean;
}): ReactElement {
    return (
        <section
            className={`${styles["wrapper"]} ${
                differentStyle ? styles["wrapperBg"] : ""
            } `}
        >
            <div
                className={`${
                    differentStyle ? "py-20 " : "lg:py-32 py-16"
                }  flex flex-col lg:flex-row  px-5 gap-[56px]`}
            >
                <div
                    className={`max-w-[575px]  mx-auto lg:mx-0 ${
                        differentStyle
                            ? "order-2 lg:flex lg:flex-col lg:justify-center"
                            : "order-1"
                    }`}
                >
                    <h2>{title}</h2>

                    {content.map((string, index) => (
                        <p className="mb-10" key={index}>
                            {string}
                        </p>
                    ))}
                </div>
                <div
                    className={`sm:w-[575px] sm:min-w-[575px] sm:h-[383px] w-[300px] h-[200px] relative  mx-auto lg:mx-0 ${
                        differentStyle ? "order-1 " : "order-2 "
                    }`}
                >
                    <Image src={image} layout="fill" alt="Image" />
                </div>
            </div>
        </section>
    );
}
